.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

img {
  &.image-responsive {
    width:100%;
    height:auto;
  }

  &.image-grey {
    filter: grayscale(100%);
  }
}

.bg-white {
  background-color: $bgWhite;
}

.bg-grey {
  background-color: $bgGrey;
}


.bg-contact {
  background-color: #e9e9e9;
}

// Margins
.m-1 {
  margin:1em;
}

.m-2 {
  margin:2em;
}

.m-3 {
  margin:3em;
}

.m-4 {
  margin:4em;
}

.m-5 {
  margin:5em;
}

.mt-1 {
  margin-top:1em;
}

.mt-2 {
  margin-top:2em;
}

.mt-3 {
  margin-top:3em;
}

.mt-4 {
  margin-top:4em;
}

.mt-5 {
  margin-top:5em;
}


.mb-1 {
  margin-bottom:1em;
}

.mb-2 {
  margin-bottom:2em;
}

.mb-3 {
  margin-bottom:3em;
}

.mb-4 {
  margin-bottom:4em;
}

.mb-5 {
  margin-bottom:5em;
}

// Paddings
.p-1 {
  padding:1em;
}

.p-2 {
  padding:2em;
}

.p-3 {
  padding:3em;
}

.p-4 {
  padding:4em;
}

.p-5 {
  padding:5em;
}

.pt-1 {
  padding-top:1em;
}

.pt-2 {
  padding-top:2em;
}

.pt-3 {
  padding-top:3em;
}

.pt-4 {
  padding-top:4em;
}

.pt-5 {
  padding-top:5em;
}


.pb-1 {
  padding-bottom:1em;
}

.pb-2 {
  padding-bottom:2em;
}

.pb-3 {
  padding-bottom:3em;
}

.pb-4 {
  padding-bottom:4em;
}

.pb-5 {
  padding-bottom:5em;
}
