@import "./variables.scss";
@import "./utilities.scss";
@import "~slick-carousel/slick/slick.scss";

// :root{
//   scroll-behavior: smooth;
// }

body {
  font-family: 'Lato', sans-serif;
  background-color:#f9f9f9;
  padding:0;
  margin:auto;
  font-weight:300;
  line-height:1.8;
  font-size:1.8em;
  color:#555555;

  &.noscroll {
    overflow-y: hidden;
  }

  .container {
    max-width: 1200px;
  }

  .section-icon {
    max-width: 400px;
  }
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Open Sans', sans-serif;
  color:$anchorColor;
  // font-size:2em;
}

h1 {
  font-size: 2em;
  font-weight:500;
}

h2 {
  font-size: 1.8em;
  font-weight:300;
}

h3 {
  font-size: 1.6em;
  font-weight:300;
}

h4 {
  font-size:1.4em;
  font-weight:300;
}

h5 {
  color: #555;
}

a, .button {
  color: $anchorColor;
  border-color: $anchorColor;
  white-space: normal;
  height: auto;
  &:hover {
    color: $anchorHoverColor;
    border-color: $anchorHoverColor;
    background-color: $anchorHoverBGColor;
  }

  &.cta {
    background-color: #44a0ce;
    border-color: #44a0ce;
    color: #ffffff;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    font-size: 0.8em;
    transition: all 0.1s linear;

    &:hover {
      background-color: #4faedd;
      border-color: #4faedd;
      transform: scale(1.1);
    }
  }
}

.fa-solid {
  border: 7px solid $anchorHoverColor;
  border-top: 0px;
  border-left: 0;
  // border-radius: 100px;
  padding: 20px;
}

.view-desktop-only {
  display:inline;
}
.view-mobile-only {
  display:none;
}

#Header {
  position: fixed;
  z-index:99;
  width:100%;
  top:0;
  left:0;
  background-color: transparent;
  display:flex;
  align-items: center;
  height:96px;
  border-bottom:3px solid transparent;
  transition: all 0.2s linear;

  #HeaderLogo {
    // filter:invert(1);
    width:300px;
    margin-top:1em;
    transition: all 0.2s linear;
  }
  #MobileLogo{
    display:none;
  }

  .mobile-menu-open {
    display:none;
  }
  .mobile-menu-close {
    display:none;
  }

  &.scrolled {
    background-color:#ffffff;
    border-bottom:3px solid $anchorHoverBGColor;
    font-size:0.9em;
    height:76px;

    #HeaderLogo {
      filter:invert(0);
    }

    #MainMenu {
      li {
        a {
          color: inherit;

          &:hover {
            background-color: $anchorHoverBGColor;
          }
        }
      }
    }
  }

  #MainMenu {
    list-style: none;
    margin:0;
    margin-top:0.4em;

    padding:0;
    padding-top:0.7em;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    li {
      a {
        border: 0;
        padding: 0 1.3em;
        margin:0;
        border-radius: 0;
        position:relative;
        color:#f9f9f9;
        font-size:0.7em;

        &:hover {
          background-color: transparent;
        }

        &:before {
          content: " ";
          position: absolute;
          left: 2px;
          top: 15px;
          width: 8px;
          height: 8px;
          background-color: $anchorHoverColor;
          opacity:0;
          transition: opacity 0.2s linear;
        }
        &:after {
          content: " ";
          position:absolute;
          left: 2px;
          top: 32px;
          width: 60%;
          height: 3px;
          background-color: $anchorColor;
          opacity: 0;
          transition: opacity 0.2s linear;
        }

        &.active {
          // border-bottom:2px solid $anchorColor;
          &:before, &:after {
            opacity:1;
          }
        }
      }
    }
  }
}

#Hero {
  // margin-top:96px;
  padding:1em 0;
  background-color: $anchorColor;
  background-image: url(./../images/hero-bg-texture.jpg);
  background-position:left;
  background-size:cover;
  background-repeat:no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 80vh;

  h1, h3 {
    color: #376987;
    font-size: 2em;
    /* text-shadow: 3px 3px 3px rgb(255 255 255 / 50%); */
    margin: 1em 0 0 0;
    margin-right: 3em;
  }
  h4 {
    color: #ffffff;
    /* text-shadow: 3px 3px 3px rgb(0 0 0 / 50%); */
    /* font-weight: 500; */
    margin-left: 3em;
  }

  .container {
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  #start {
    position: absolute;
    bottom: 20%;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    margin: auto;
    text-align: center;
    border: 3px solid $anchorHoverColor;
    border-radius: 40px;
    padding-top: 0.8em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-bottom: 0.1em;
    opacity: 0.7;
    transition: all 0.2s linear;
    transform: scale(0.8);

    i {
      border: 0;
      color: white;
      padding: 0;
      margin: auto;
    }

    &:hover, &.active {
      opacity: 1;
      transform: scale(1);
      border-color: white;
    }
  }
}

#Intro {
}

#Services {
  ul.services-list {
    list-style: none;
    margin: 0;
    margin-top: 5em;
    padding: 0;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    li {
      flex-basis: 30%;
      margin-bottom: 3em;
      padding: 1em;
      transition: background 0.2s linear;

      h4 {
        min-height: 70px;
      }
      

      .services-icon {
        position:relative;
        width:120px;
        height:120px;
        margin:auto;
      
        .fa-solid {
          position:absolute;
          top:0.1em;
          left:0.05em;
          border:0;
          transform:rotate(-45deg);
          transition: transform 0.2s linear;
        }
      
        .diamond-bg {
          position:absolute;
          width:100px;
          height:100px;
          border:7px solid #5ba5d0;
          border-top: 0px;
          border-bottom: 0;
          transform:rotate(45deg);
          transition: transform 0.2s linear;
        }
      }

      &:hover {
        background-color: #fafafa;
        .fa-solid {
          transform: rotate(0deg);
        }

        .diamond-bg {
          transform: rotate(0deg);
        }
      }
    }
  }
}

#Products {
}

#Portfolio {
  #portfolio-slider {

    img {
      // border: 1px solid #eee;
      // box-shadow: 3px 3px 3px #aaa;
    }

    button {
      border: 0;
      border: 1px solid #317a96;
      color: #317a96;
      border-radius: 25px;
      padding: 0.2em 1.5em;
      margin: 0.3em;
      height: auto;
      transition: all 0.2s linear;

      &:hover {
        background-color:#5ba5d0;
        border-color: #5ba5d0;
        color: white;
      }
      .fa-solid {
        border: 0;
        padding: 0;
        margin-top: 10px;
      }

      &.slick-arrow {
        position: absolute;
        z-index: 9;
        top: 50%;
        &.slider-prev {
          left: -80px;
        }

        &.slider-next {
          right: -80px;
        }
      }
    }

    .portfolio-item {
      // height: 450px;
      display:flex;

      .description {
        flex-basis: 40%;

        img {
          width: 240px;
          height: auto;
        }
      }

      h3 {
        font-size: 1.2em;
        text-align: left;
        margin-bottom: 0;
      }
      span {
        opacity: 0.8;
      }

      p {
        font-size: 0.8em;
        color: #888;
      }

      ul.tag {
        list-style: none;
        margin: 0;
        margin-top: 0.2em;
        padding: 0;
        display: flex;
        margin-bottom: 1em;

        li {
          flex-basis: 30%;
          font-size: 0.6em;
          padding: 0;
          border:1px solid #5ba5d0;
          color: #5ba5d0;
          margin: 0.2em;
          text-align: center;
          border-radius: 3px;
        }
      }

      img {
        margin: auto;
        width: auto;
        height: 500px;
        padding: 0 2em;
      }
    }

    .slider-dots {
      list-style: none;
      display: flex;
      justify-content: center;
      height: 2px;
      li {
        button {
          background-color: $anchorHoverColor;
          height: 2px;
          overflow: hidden;
          color: transparent;
          border: 0;
          border-radius: 4px;
          // border-bottom: 3px solid red;
        }

        &.slick-active {
          button {
            background-color: $anchorColor;
          }
          
        }
      }
    }
  }

  #portfolio-slider-nav {
    

    .slick-slide {
      opacity: 0.4;
      transition: opacity 0.2s linear;

      &.slick-center {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

#Partners {
  ul.partners {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    li {
      flex-basis: 25%;
      height: 100px;

      img {
        width: 100%;
        max-width: 160px;
        height: auto;
        margin: 0;
        padding: 0;
        filter: grayscale(0.5);
        opacity: 0.75;
        transition: all 0.2s linear;

        &:hover {
          opacity: 1;
          filter: grayscale(0);
        }
      }
    }
  }
}

#Contact {
  form {
    label {
      font-weight:300;
    }

    textarea {
      height:200px;
      resize:none;
    }
  }
}

footer {
  padding:4em 0;
  background-color:#284d5a;
  color:#f9f9f9;

  ul {
    list-style: none;
    padding:0;
    margin:0;
  }
}

.modal-overlay {
  width:0;
  height:0;
  position:fixed;
  top:50%;
  bottom:50%;
  left:50%;
  right:50%;
  z-index:100;
  background-color:rgba(0,0,0,0.9);
  overflow-y:scroll;
  color: white;
  opacity:0;
  transform: scale(0.1,0.1);
  transition: all 0.3s cubic-bezier(0.1,0.6,0.2,1);
  // transition: all 0.3s cubic-bezier(1,0.6,0.3,0.1);
  h2 {
    color: #fff;
  }
  h3, h4 {
    color: $anchorHoverColor;
  }

  .modal-close {
    color:white;
    border-color:white;
    &:hover {
      background-color:rgba(255,255,255,0.3);
    }
  }

  &.show {
    width:100%;
    height:100%;
    left:0;
    top:0;
    right:0;
    bottom:0;
    opacity:1;
    transform: scale(1,1);
  }
}

.products-columns {
  margin-bottom:3em;
  .product {
    height:400px;
    padding:1em;
    position: relative;
    // background-attachment: fixed;
    background-position: center;
    border: 1px solid #e7edf0;
    // filter: grayscale(0.75);
    transition: filter 0.2s linear;

    &:hover {
      filter: grayscale(0);
    }

    .description {
      background-color: rgba(255,255,255,0.9);
      position: absolute;
      top:0;
      left: 0;
      right: 0;
      padding: 1em;
      box-shadow: 1px 1px 1px #e7edf0;
      min-height: 120px;

      a {
        color: #62899f;
        font-weight: 600;
        text-decoration: none;        
        // text-transform: uppercase;

        &:hover {
          background-color: transparent;
        }        
      }

      
    }

    .link {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 2em;
      width: 50%;
      padding: 0.5em;
      background-color: #317a96;
      color: white;
      text-transform: none;
      text-align: center;
      margin: auto;
      text-decoration: none;
      font-size: 0.8em;
      transition: background 0.2s linear;
      border-radius: 4px;

      &:hover {
        background-color: #5ba5d0;
      }
    }


    &.product-pal {
      background-image:url(../images/product-pal.png);
    }
    &.product-vattendance {
      background-image:url(../images/product-vattendance.png);
    }
    &.product-clinicops {
      background-image:url(../images/product-clinicops.png);
    }

    &.soon {
      filter: grayscale(0.7);
      opacity: 0.7;
    }
  }

}

footer {
  ul.quicklinks {
    li {
      margin-bottom: 0;
      color: #aaa;
    }
  }

  ul.social {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    li {
      flex-basis: 20%;
      a, a:hover {
        color: white;
        background-color: transparent;
        opacity: 0.8;
      }
    }
  }
}


@import "./mobile.scss";
