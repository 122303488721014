@media(max-width: 1007px){

  h1 {
    font-size: 1.8em;
  }

  .view-desktop-only {
    display:none;
  }
  .view-mobile-only {
    display:inline;
  }  

  #Header {
    #HeaderLogo {
      // display:none;
      width:75% !important;
    }
    #MobileLogo {
      display:inline !important;
      width:75%;
      margin-top:10px;
      margin-left:-20px;
    }
    &.scrolled {
      font-size:1em !important;

      .mobile-menu-open {
        color:$anchorColor;
      }
    }
    .menu-container {
      overflow: hidden;
      width:0;
      height:0;
      top:0;
      right:0;
      opacity:0;
      transition: all 0.3s cubic-bezier(0.1,0.6,0.2,1);

      #MainMenu {

        flex-direction: column;
        margin-top:-10px;

        li {
          a {
            color: #333;
            font-size: 1.3em;
            margin-bottom: 0.5em;

            &:before {
              content: " ";
              position: absolute;
              left: 2px;
              top: 4px;
              width: 24px;
              height: 24px;
              background-color: $anchorHoverColor;
              opacity: 0;
              transition: opacity 0.2s linear;
            }
            &:after {
              content: " ";
              position:absolute;
              left: 2px;
              top: 42px;
              width: 60%;
              height: 6px;
              background-color: $anchorColor;
              opacity: 0;
              transition: opacity 0.2s linear;
            }

            &:hover {
              background-color: transparent !important;
            }

            &.active {
              // border-bottom:2px solid $anchorColor;
              background-color:transparent;
              &:before, &:after {
                opacity:1;
              }
            }
          }
        }
      }

      &.show {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity:1;
        background-color: rgba(255,255,255,0.9);
      }



      .mobile-menu-close {
        display:inline;
        border:3px solid #333;
        border-radius:50px;
        padding:10px 20px;
        font-size:2em;
        text-decoration: none;
        color:#333;
        font-weight:600;
      }
    }

    .mobile-menu-open {
      display:inline !important;
      text-decoration: none;
      color: #fff;
      font-weight: 600;
      border: 0;
      position: absolute;
      top: 14px;
      right: -24px;
      .fa-solid {
        border:0;
      }
    }
  }

  #Hero {
    background-position:20%;

    h1 {
      font-size: 1.8em;
      margin-right: 0;
    }

    h4 {
      margin-left: 0;
      margin-top: 1em;
    }

    .container {
      top: 15% !important;

      #start {
        bottom: 10% !important;
      }
    }
  }

  .modal-overlay {
    .modal-close {
      position:absolute;
      top:1em;
      right:1em;
      z-index:200;
    }
  }

  #Services {
    ul.services-list {
      flex-direction: column;
      
      li {
        flex-basis: 100%;
        padding: 0;
      }
    }
  }

  .product {
    margin-bottom: 2em;
    text-align: left;
    filter: grayscale(0) !important;

    .description {
      min-height: unset !important;
    }
  }

  #Portfolio {
    .portfolio-item {
      flex-direction: column;

      img {
        padding: 0 !important;
        width: 100% !important;
        height: auto !important;
      }
    }
    
    
    .slick-arrow {      
      display: none !important;
      top: 75% !important;

      &.slider-prev {
        left: -40px !important;
      }

      &.slider-next {
        right: -40px !important;
      }
    }

    .slider-dots {
      justify-content: space-between !important;
      li {
        width: 20px !important;

        button {
          width: 20px !important;
          padding: 0.2em 0 !important;
        }
      }
    }
  }

}

@media(max-width: 549px) {
  .mobile-reverse-flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}